<template>
  <div class="contract-table">
    <div v-for="(thirdParty, index) in thirdPartyRows" :key="index">
      <div
        v-if="page.subpages[index].check"
        :id="`doc-${page.id}-${thirdParty.id}`"
      >
        <table :class="'a4 pg' + thirdParty.id" width="100%" border="0">
          <tbody>
            <tr>
              <td class="td">
                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td valign="top" style="padding-top: 5px">
                        <img
                          :src="contract.company_branch.logo"
                          alt="logo"
                          style="height: 50px"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        style="
                          font-size: 18px;
                          padding-top: 10px;
                          padding-bottom: 0px;
                        "
                      >
                        <strong style="font-weight: 600"
                          >AUTORIZAÇÃO DE PAGAMENTO PARA TERCEIROS - ANEXO
                          II</strong
                        >
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="center"
                        style="font-size: 11px; padding-bottom: 20px"
                      >
                        A
                        {{ contract.company_branch.name }}
                        lhe dá as boas-vindas e agradece a preferência!
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        style="
                          background: #e5e5e5;
                          color: #444;
                          font-size: 14px;
                          padding: 5px 10px;
                          font-weight: 600;
                        "
                      >
                        DADOS DO CLIENTE PAGANTE
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 12px"
                  width="100%"
                >
                  <tr>
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">NOME:</strong>
                      {{ thirdPartyData(thirdParty.id).nome }}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">NASCIMENTO:</strong>
                      {{ thirdPartyData(thirdParty.id).nascimento }}
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">CPF:</strong>
                      {{ thirdPartyData(thirdParty.id).cpf }}
                    </td>
                    <td
                      width="146"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">RG:</strong>
                      {{ thirdPartyData(thirdParty.id).rg }}
                    </td>
                    <td
                      width="219"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">ORGÃO EMISSOR:</strong>
                      {{ thirdPartyData(thirdParty.id).orgao }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">ENDEREÇO:</strong>
                      {{ thirdPartyData(thirdParty.id).rua }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="221"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">N°:</strong>
                      {{ thirdPartyData(thirdParty.id).numero }}
                    </td>
                    <td
                      colspan="3"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">COMPLEMENTO: </strong>
                      {{ thirdPartyData(thirdParty.id).complemento }}
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">BAIRRO:</strong>
                      {{ thirdPartyData(thirdParty.id).bairro }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="221"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">ESTADO:</strong>
                      {{ thirdPartyData(thirdParty.id).estado }}
                    </td>
                    <td
                      colspan="5"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">CIDADE:</strong>
                      {{ thirdPartyData(thirdParty.id).cidade }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">TELEFONE:</strong>
                      {{ thirdPartyData(thirdParty.id).telefone }}
                    </td>
                    <td
                      colspan="3"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">CELULAR:</strong>
                      {{ thirdPartyData(thirdParty.id).celular }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="6"
                      style="
                        border-color: #eee;
                        border-width: 0 0 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">E-MAIL:</strong>
                      {{ thirdPartyData(thirdParty.id).email }}
                    </td>
                  </tr>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td align="center" style="padding-bottom: 30px"></td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0">
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        style="
                          background: #e5e5e5;
                          color: #444;
                          font-size: 14px;
                          padding: 5px 10px;
                          font-weight: 600;
                        "
                      >
                        TERCEIROS BENEFICIÁRIOS
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 12px; background: #f9f9f9"
                >
                  <tr>
                    <td
                      width="7%"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                        text-align: center;
                      "
                    >
                      <strong style="font-weight: 600">#</strong>
                    </td>
                    <td
                      width="69%"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">NOME </strong>(Primeiro
                      nome e sobrenome)
                    </td>
                    <td
                      width="24%"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      <strong style="font-weight: 600">CPF</strong>
                    </td>
                  </tr>
                </table>

                <table
                  width="100%"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style="font-size: 12px"
                >
                  <tr v-for="(traveller, index) in travellers" :key="index">
                    <td
                      width="7%"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                        text-align: center;
                      "
                    >
                      <strong style="font-weight: 600">{{
                        traveller.id
                      }}</strong>
                    </td>
                    <td
                      width="69%"
                      style="
                        border-color: #eee;
                        border-width: 0 1px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      {{
                        contract.meta[`traveller_${traveller.id}_first_name`]
                      }}
                      {{ contract.meta[`traveller_${traveller.id}_last_name`] }}
                    </td>
                    <td
                      width="24%"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 1px 0;
                        border-style: solid;
                        padding: 5px;
                      "
                    >
                      {{ contract.meta[`traveller_${traveller.id}_cpf`] }}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colspan="4"
                      style="
                        border-color: #eee;
                        border-width: 0 0px 0px 0;
                        border-style: solid;
                        padding: 10px 0 5px;
                        font-size: 11px;
                      "
                    >
                      <p>
                        Autorizo a
                        {{
                          contract.company_details != undefined
                            ? contract.company_details.company_name +
                              ", CNPJ " +
                              contract.company_details.cnpj
                            : ""
                        }}, em caráter irrevogável e irretratável, a processar a
                        cobrança no meu cartão de crédito conforme Contrato e
                        Autorização de Débito por mim assinados em benefício aos
                        terceiros citados acima.
                      </p>

                      <pre></pre>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="page-break-after: always;"></div>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ThirdPartyDocument",
  props: {
    page: Object,
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      thirdPartyRows: [],
      travellers: [],
    };
  },
  mounted() {
    this.thirdPartyRows =
      this.contract.meta.third_party_payment_rows != undefined
        ? JSON.parse(this.contract.meta.third_party_payment_rows)
        : [];

    this.travellers =
      this.contract.meta.travellers_rows != undefined
        ? JSON.parse(this.contract.meta.travellers_rows)
        : [];
  },
  methods: {
    thirdPartyData(id) {
      return {
        id: id,
        check: true,
        nome:
          this.contract.meta[
            "third_party_client_first_name_" + id
          ].toUpperCase() +
          " " +
          this.contract.meta[
            "third_party_client_last_name_" + id
          ].toUpperCase(),
        nascimento:
          this.contract.meta["third_party_client_birthday_" + id] != undefined
            ? this.formatMultiDates(
                this.contract.meta["third_party_client_birthday_" + id]
              )
            : "",
        cpf: this.contract.meta["third_party_client_CPF_" + id],
        orgao:
          this.contract.meta[
            "third_party_client_doc_emission_" + id
          ].toUpperCase() +
          "-" +
          this.contract.meta["third_party_client_doc_emission_state_" + id],
        rg: this.contract.meta["third_party_client_RG_" + id],
        telefone: this.contract.meta["third_party_client_phone_" + id],
        celular: this.contract.meta["third_party_client_mobile_phone_" + id],
        email:
          this.contract.meta["third_party_client_email_" + id] != undefined
            ? this.contract.meta["third_party_client_email_" + id].toUpperCase()
            : "",
        rua:
          this.contract.meta["third_party_client_location_street_" + id] !=
          undefined
            ? this.contract.meta[
                "third_party_client_location_street_" + id
              ].toUpperCase()
            : "",
        numero: this.contract.meta["third_party_client_location_number_" + id],
        bairro:
          this.contract.meta[
            "third_party_client_location_neighborhood_" + id
          ] != undefined
            ? this.contract.meta[
                "third_party_client_location_neighborhood_" + id
              ].toUpperCase()
            : "",
        complemento:
          this.contract.meta["third_party_client_location_complement_" + id] !=
          undefined
            ? this.contract.meta[
                "third_party_client_location_complement_" + id
              ].toUpperCase()
            : "",
        estado: this.contract.meta["third_party_client_location_state_" + id],
        cidade:
          this.contract.meta["third_party_client_location_city_" + id] !=
          undefined
            ? this.contract.meta[
                "third_party_client_location_city_" + id
              ].toUpperCase()
            : "",
        cep: this.contract.meta["third_party_client_CEP_" + id],
        valor: this.formatAnyPricePtBr(
          this.contract.meta["third_party_client_paid_value_" + id]
        ),
      };
    },
  },
};
</script>

<style>
.contract-table {
  margin: 0 auto;
  width: 793px;
}

.contract-table .a4 {
  background: #fff;
  margin-bottom: 20px;
  height: 1122px;
  border: 1px solid #fff;
}

.contract-table .a4 .td {
  padding: 20px;
  height: 1112px;
  vertical-align: top;
  color: #666;
  font-size: 12px;
}
</style>
